@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;1,100&display=swap");

body,
html {
  font-family: "Roboto", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
* {
  margin: 0;
  padding: 0;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.latest {
  color: #b32d2a;
}

.fade-in {
  animation: fadeIn 5s;
  color: #b32d2a;
}
body {
  overflow-x: hidden;
}
.container {
  overflow-x: hidden;
}

.glow {
  font-size: 80px;
  color: #fff;
  text-align: center;
  animation: glow 1s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073,
      0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
  }

  to {
    text-shadow: 0 0 200px #fff, 0 0 300px #ff4da6, 0 0 400px #ff4da6,
      0 0 500px #ff4da6, 0 0 600px #ff4da6, 0 0 700px #ff4da6, 0 0 800px #ff4da6;
  }
}

/*.arrow-1 {*/
/*    width:50px;*/
/*    height:30px;*/
/*    display: flex;*/
/*}*/
/*.arrow-1:before {*/
/*    content: "";*/
/*    background: currentColor;*/
/*    width:15px;*/
/*    clip-path: polygon(0 10px,calc(100% - 15px) 10px,calc(100% - 15px) 0,100% 50%,calc(100% - 15px) 100%,calc(100% - 15px) calc(100% - 10px),0 calc(100% - 10px));*/
/*    animation: a1 1.5s infinite linear;*/
/*}*/
/*@keyframes a1 {*/
/*    90%,100%{flex-grow: 1}*/
/*}*/
